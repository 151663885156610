import * as auth from "@service/auth/initAuth";

import { GlobalState, defaultGlobalState } from "./GlobalContext";

export async function initGlobalState(): Promise<GlobalState> {
	const state = structuredClone(defaultGlobalState);

	try {
		state.auth = {
			isLoggedIn: await auth.isLoggedIn(),
		};
	} catch (error) {
		console.error(error);
	}

	return state;
}
