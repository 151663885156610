import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { BalanceAccountWithTransactions } from "@models";
import {
	ActionButton,
	Button,
	FormContainer,
	Input,
	QueryStateIndicators,
	RadioButton,
} from "@components";
import styled from "styled-components";
import { useAsyncCommand, useDialog, useServices } from "@hooks";
import { useRouteMatch } from "react-router-dom";
import { UserRouteParams } from "@service/navigation/routes";
import { BalanceAccountUpdateType } from "@models";
import UserDetailsPageContext from "../../../user/UserDetailsPageContext";
import { defer } from "@util/helpers";
import { Currency } from "@util";

type Props = {
	balanceAccount: BalanceAccountWithTransactions;
	hideBalanceAccountControls: () => void;
	onClose?: (needsConfirmation: boolean) => void;
	onFormDirty?: (dirty: boolean) => void;
};

type SelectConfiguration =
	| "changeByDifferenceAmount"
	| "changeByAbsoluteAmount";

type FormInput = {
	differenceAmount: number;
	absoluteAmount: number;
};

export default function BalanceAccountControls(props: Props) {
	const { balanceAccount, onFormDirty } = props;
	const { register, handleSubmit, formState, setValue } = useForm<FormInput>({
		mode: "onChange",
	});
	const { params } = useRouteMatch<UserRouteParams>();
	const { userId } = params;

	const [selectedConfiguration, setSelectedConfiguration] =
		useState<SelectConfiguration>("changeByDifferenceAmount");

	const pageContext = useContext(UserDetailsPageContext);

	const { balanceAccountService } = useServices();

	const [updateBalanceAccount, updateQuery] = useAsyncCommand(
		(balanceUpdateType: BalanceAccountUpdateType, amount: number) => {
			const payload = {
				userId: userId,
				balanceAccountId: balanceAccount.balanceAccountId,
				updateType: balanceUpdateType,
				amount: amount,
			};
			return balanceAccountService.updateBalanceAccount(payload);
		}
	);

	const [updateBalanceBudget, updateBalanceBudgetsResult] = useAsyncCommand(
		() =>
			balanceAccountService.updateBalanceBudgets(
				userId,
				balanceAccount.balanceAccountId
			)
	);

	const handleSubmitForm = handleSubmit(async (formValues: FormInput) => {
		const { balanceUpdateType, amount } = getBalanceTypeAndAmount(
			selectedConfiguration,
			formValues
		);
		const result = await updateBalanceAccount(balanceUpdateType, amount * 100);
		if (result.state === "success") {
			pageContext.triggerRefresh();
		}
	});

	const updateBudgetsDialog = useDialog();
	const onClickPayoutToBudget = () => {
		updateBudgetsDialog.showConfirmDialog({
			title: "Auszahlen",
			text: `Das aktuelle Budget wird mit bis zu ${Currency.format(
				balanceAccount.balance,
				{ hideZeroDecimals: true }
			)} aufgeladen. Sind Sie sicher?`,
			confirmText: "Bestätigen",
			onConfirm: async () => {
				const result = await updateBalanceBudget();
				if (result.state === "success") {
					await defer(() => {
						props.hideBalanceAccountControls();
						props.onClose?.(false);
						pageContext.triggerRefresh();
					});
				}
			},
		});
	};

	useEffect(() => {
		// note: this is quite ugly, but we want to make sure to prevent accidentally closing the card if the form is dirty
		onFormDirty?.(formState.isDirty);
	}, [onFormDirty, formState.isDirty]);

	return (
		<>
			<StyledFormContainer data-testid="balanceAccountControlPanel">
				<Form onSubmit={handleSubmitForm}>
					<FormGroup as={Row}>
						<Col>
							<RadioButton
								id="changeByDifferenceAmount"
								label="Aufladen (in Euro)"
								value={selectedConfiguration === "changeByDifferenceAmount"}
								onChange={() =>
									setSelectedConfiguration("changeByDifferenceAmount")
								}
							/>
						</Col>
					</FormGroup>

					<FormGroup as={Row}>
						<Col>
							<Input
								type="number"
								{...register("differenceAmount")}
								id="chargeAmount"
								defaultValue={0}
								disabled={selectedConfiguration !== "changeByDifferenceAmount"}
								data-testid="differenceAmountInput"
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const differenceAmount = Number.parseInt(e.target.value);
									const absoluteAmount =
										balanceAccount.balance / 100 + differenceAmount;
									setValue("absoluteAmount", absoluteAmount);
								}}
							/>
						</Col>
					</FormGroup>

					<FormGroup as={Row}>
						<Col>
							<RadioButton
								id="changeByAbsoluteAmount"
								value={selectedConfiguration === "changeByAbsoluteAmount"}
								label="Neuen Kontostand festlegen (in Euro)"
								onChange={() =>
									setSelectedConfiguration("changeByAbsoluteAmount")
								}
							/>
						</Col>
					</FormGroup>

					<FormGroup as={Row}>
						<Col>
							<Input
								type="number"
								{...register("absoluteAmount")}
								id="absoluteAmount"
								defaultValue={balanceAccount.balance / 100}
								disabled={selectedConfiguration !== "changeByAbsoluteAmount"}
								data-testid="absoluteAmountInput"
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const absoluteAmount = Number.parseInt(e.target.value);
									const differenceAmount =
										absoluteAmount - balanceAccount.balance / 100;
									setValue("differenceAmount", differenceAmount);
								}}
							/>
						</Col>
					</FormGroup>

					<ButtonContainer>
						<div>
							<ActionButton
								type="button"
								variant="outline-primary"
								onClick={onClickPayoutToBudget}
								disabled={balanceAccount.balance === 0}
								query={updateBalanceBudgetsResult}
							>
								Auszahlen
							</ActionButton>
						</div>
						<div>
							<ActionButton
								type="submit"
								query={updateQuery}
								successContent={"Gespeichert"}
							>
								Speichern
							</ActionButton>
							<Button
								variant="outline-primary"
								onClick={() => props.onClose?.(true)}
							>
								Abbrechen
							</Button>
						</div>
					</ButtonContainer>
				</Form>
			</StyledFormContainer>
			<QueryStateIndicators queryState={updateBalanceBudgetsResult} />
		</>
	);
}

const StyledFormContainer = styled(FormContainer)`
	border: 1px solid gray;
	margin: 8px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

function getBalanceTypeAndAmount(
	selectedConfiguration: SelectConfiguration,
	formValues: FormInput
) {
	const { differenceAmount, absoluteAmount } = formValues;

	return {
		balanceUpdateType:
			selectedConfiguration === "changeByDifferenceAmount"
				? BalanceAccountUpdateType.Difference
				: BalanceAccountUpdateType.Absolute,
		amount:
			selectedConfiguration === "changeByDifferenceAmount"
				? differenceAmount
				: absoluteAmount,
	};
}
