import { ErrorBase } from "@util/errors";

export class InvalidSalutationTemplateError extends ErrorBase {}

export class UserNotFoundError extends ErrorBase {}

export class SendingEmailError extends ErrorBase {}

export class UpdateUserError extends ErrorBase {}

export class ResalutateUnexpectedStateError extends ErrorBase {}

export class UserHasProductsError extends ErrorBase {}

export class InvalidEmailFormatError extends ErrorBase {}

export class PersonalnoConflictError extends ErrorBase {}

export class UserConflictError extends ErrorBase {
	constructor(readonly reason: "email" | "personalNo" | "externalId") {
		const map: Record<typeof reason, string> = {
			email: "E-Mail Adresse wird bereits verwendet",
			personalNo: "Personal-Nummer ist bereits vergeben",
			externalId: "Externe ID ist bereits vergeben",
		};
		super(map[reason]);
	}
}

export class OverlappingUsersError extends ErrorBase {}

export class UserAssignedAsSuperiorError extends ErrorBase {}

export class CyclicReferenceError extends ErrorBase {}

export class DebitorNumberNotFoundError extends ErrorBase {}
