import { deserializeDate } from "@util";
import { Mutable, Properties } from "@util/types";

export enum CustomerStatus {
	Trial = "trial",
	Active = "active",
	Deactivated = "deactivated",
	Archived = "archived",
}

export default class Customer {
	constructor(props: Properties<Customer>) {
		this.customerId = props.customerId;
		this.customerNo = props.customerNo;
		this.name = props.name;
		this.status = props.status;
		this.legalForm = props.legalForm;
		this.created = props.created;
		this.updated = props.updated;
	}

	readonly customerId: string;
	readonly customerNo: number | null;
	readonly name: string;
	readonly status: CustomerStatus;
	readonly legalForm: string;
	readonly created: Date | null;
	readonly updated: Date | null;

	public static fromJson(data: any): Customer {
		return new Customer({
			...data,
			created: deserializeDate.nullable.dateTime(data["created"]),
			updated: deserializeDate.nullable.dateTime(data["updated"]),
		});
	}
}

export type NewCustomer = Mutable<
	Pick<Customer, "name" | "legalForm" | "status">
>;
export type UpdatedCustomer = Mutable<Pick<Customer, "name" | "legalForm">>;
