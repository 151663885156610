import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import { Icon, IconButton, TableColumn, IconsContainer } from "@components";
import Routes from "@service/navigation/routes";
import { formatDate } from "@service/util";
import Division from "@models/Division";
import ErrorAlert, { alertStyle } from "@components/ErrorAlert";
import { useDialog, useServices, useAsyncCommand } from "@hooks";
import { StatusSpan } from "@components/StatusToggle";

type Props = {
	division: Division;
};

export default function DivisionRow({ division }: Props) {
	const { divisionService } = useServices();
	const [deleteDivisionFn, deleteQueryResult] = useAsyncCommand(
		(divisionId: string, customerId: string) =>
			divisionService.deleteDivision(customerId, divisionId)
	);
	const history = useHistory();
	const deleteDialog = useDialog();

	const handleDeleteDivision = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();

		deleteDialog.showConfirmDialog({
			title: "Standort löschen",
			text: "Sind Sie sicher, dass Sie den Standort löschen möchten?",
			onConfirm: () => {
				void deleteDivisionFn(division.customerId, division.divisionId);
			},
		});
	};

	const divisionUsersUrl = Routes.Users.makePath({
		customerId: division.customerId,
		divisionId: division.divisionId,
	});

	const LinkableColumn = (props: { children: React.ReactNode }) => (
		<TableColumn onClick={() => history.push(divisionUsersUrl)}>
			{props.children}
		</TableColumn>
	);

	const row = (
		<ClickableRow>
			<LinkableColumn>
				<StatusSpan status={division.status} />
			</LinkableColumn>
			<LinkableColumn>{division.name}</LinkableColumn>
			<LinkableColumn>{division.street}</LinkableColumn>
			<LinkableColumn>{division.phone}</LinkableColumn>
			<LinkableColumn>{division.stid}</LinkableColumn>
			<LinkableColumn>{division.ustid}</LinkableColumn>
			<LinkableColumn>
				{division.created ? formatDate(division.created) : "-"}
			</LinkableColumn>
			<LinkableColumn>
				{division.updated ? formatDate(division.updated) : "-"}
			</LinkableColumn>
			<TableColumn>
				<IconsContainer>
					<Link to={divisionUsersUrl}>
						<IconButton tooltip="Benutzer anzeigen">
							<Icon.Details test-dataid="details-button" />
						</IconButton>
					</Link>
					<Link
						to={Routes.Settings.makePath({
							customerId: division.customerId,
							divisionId: division.divisionId,
						})}
					>
						<IconButton tooltip="Standort bearbeiten">
							<Icon.Edit test-dataid="edit-button" />
						</IconButton>
					</Link>
					<IconButton tooltip="Standort löschen">
						<Icon.Delete onClick={handleDeleteDivision} />
					</IconButton>
				</IconsContainer>
			</TableColumn>
			{deleteQueryResult.state === "success" && (
				<Alert style={alertStyle} variant="success">
					Kunde erfolgreich gelöscht
				</Alert>
			)}
			{deleteQueryResult.state === "error" && <ErrorAlert />}
		</ClickableRow>
	);

	return row;
}

const ClickableRow = styled.tr`
	cursor: pointer;
`;
