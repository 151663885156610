import { Card } from "@components";
import { roleNameMapping, UserRole } from "@models/UserRole";
import React, { useCallback } from "react";
import { useFetchedData, useServices } from "@hooks";

type Props = {
	customerId: string;
	roles: UserRole[];
};

export default function UserCustomerRoles(props: Props) {
	const { customerId, roles } = props;
	const { customerService } = useServices();

	const fetchCustomer = useCallback(
		() => customerService.getCustomerById(customerId),
		[]
	);

	const [customerQuery] = useFetchedData(fetchCustomer);

	return (
		<Card
			css={`
				margin-bottom: 16px;
			`}
		>
			{customerQuery.data && <h4>{customerQuery.data.name}</h4>}
			<hr />
			{roles.length === 0 ? (
				<p>Keine Rollen</p>
			) : (
				<ul>
					{roles.map((userRole) => (
						<li>{roleNameMapping[userRole.role]}</li>
					))}
				</ul>
			)}
		</Card>
	);
}
