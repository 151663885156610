import { ErrorBase } from "@util/errors";
import React from "react";
import styled from "styled-components";
import { ErrorAlert } from ".";
import { QueryState } from "../hooks/useFetchedData";

export type MapErrorToElementFn = (error: Error) => React.ReactNode;

type Props = {
	queryState: QueryState<any> | QueryState<any>[];
	children?: MapErrorToElementFn;
	sticky?: boolean;
};

export default function QueryError(props: Props) {
	const { children, queryState } = props;
	const queryStates = Array.isArray(queryState) ? queryState : [queryState];

	const getError = (errorState: QueryState<any>) => {
		if (errorState.state === "error") {
			if (children) {
				const customError = children(errorState.error);
				if (customError !== undefined) {
					return customError;
				}
			}
			if (errorState.error instanceof ErrorBase) {
				return errorState.error.displayText;
			} else {
				return errorState.error.message || "Es ist ein Fehler aufgetreten.";
			}
		}
	};

	if (queryStates.length > 0)
		return (
			<QueryErrorContainer>
				{queryStates.map((errorState, index) => {
					const error = getError(errorState);
					if (error) {
						return (
							<ErrorAlert key={index} sticky={false}>
								{error}
							</ErrorAlert>
						);
					}
				})}
			</QueryErrorContainer>
		);
	return null;
}

const QueryErrorContainer = styled.div`
	position: fixed;
	top: 10px;
	width: 60%;
	left: 20%;
	z-index: 9999;
`;
