import React, { useState } from "react";
import styled from "styled-components";
import { Button, Icon, CommonModal, ErrorAlert, Tooltip } from "@components";
import { Header } from "@components/TableGeneric/TableTypes";
import UploadCSV from "./components/UploadCSV";
import { CsvHelpModal } from "./components/CsvHelpModal";
import {
	CSVDataAndHeader,
	DownloadableCSV,
} from "./components/DownloadableCSV";
import { Link } from "react-router-dom";
import CsvHelpCard, { CsvColumnInfoProps } from "./components/CsvHelpCard";
import { useServices } from "@hooks";
import { Division } from "@models";
import { Alert } from "react-bootstrap";
import { CsvImportType } from "@service/validation/validation.service";
import { IconWrapper, LineSpacer } from "./components/StyledComponents";
import { addMonths, format, startOfMonth } from "date-fns";
import { ErrorBase } from "@util/errors";

type ImportCSVPageProps = {
	division: Division;
};

export default function ImportCSVPage(props: ImportCSVPageProps) {
	const { division } = props;
	const [showCSVModalUploadModal, setShowCSVModalUploadModal] = useState(false);
	const [csvImportType, setCsvImportType] = useState<CsvImportType | null>(
		null
	);
	const [downloadError, setDownloadError] = useState<ErrorBase>();
	const { userService } = useServices();
	const sachbezugUserCsvFileName = `sachbezug-benutzer-datei-${division.name}-${
		new Date().getMonth() + 1
	}.${new Date().getFullYear()}.csv`;
	const downloadOrderFileIsDisable = new Date().getDate() <= 14;

	const commonContractReqCols: Omit<CsvColumnInfoProps, "de">[] = [
		{
			attribute: "personnelNumber",
			type: "required",
		},
		{
			attribute: "externalId",
			type: "required",
		},
		{
			attribute: "startDate",
			type: "required",
			description:
				"Ab diesem Datum wird der Dauerbeleg in das Budget des Mitarbeiters aufgenommen.",
		},
		{
			attribute: "name",
			type: "required",
		},
	];

	const commonContractOptCols: Omit<CsvColumnInfoProps, "de">[] = [
		{
			attribute: "endDate",
			type: "optional",
			description:
				"Bis zu diesem Datum wird der Dauerbeleg in das Budget des Mitarbeiters aufgenommen.",
		},
		{
			attribute: "netDeduction",
			type: "optional",
		},
		{
			attribute: "grossDeduction",
			type: "optional",
		},
		{
			attribute: "overflowBudgetAmountIn",
			type: "optional",
		},
		{
			attribute: "comment",
			type: "optional",
		},
	];

	const downloadSachbezugUser = async () => {
		try {
			setDownloadError(undefined);
			const couponsDataUrl = await userService.downloadSachbezugUserCsv(
				division.divisionId
			);
			const link = document.createElement("a");
			link.href = couponsDataUrl;
			link.setAttribute("download", sachbezugUserCsvFileName);
			link.click();
		} catch (e: unknown) {
			if (e instanceof ErrorBase) {
				setDownloadError(e);
			}
		}
	};

	return (
		<div>
			<ImportCSVContainer>
				<Alert variant="info">
					Weitere Informationen finden Sie im{" "}
					<Link
						to={{
							pathname:
								"https://lofino.atlassian.net/wiki/spaces/WH/pages/387186721/CSV+Imports",
						}}
						target="_blank"
					>
						Wissenshub
					</Link>{" "}
					und auf{" "}
					<Link
						to={{
							pathname: "https://docs.lofino.de/csv-imports/",
						}}
						target="_blank"
					>
						docs.lofino.de
					</Link>
				</Alert>
				<Title>
					Import Benutzer Datei
					<CsvHelpModal type={"employeeImport"} />
				</Title>
				<ImportCSVContainerBody>
					<CsvHelpCard
						columns={[
							{
								attribute: "personnelNumber",
								type: "required",
							},
							{
								attribute: "salutation",
								type: "required",
							},
							{
								attribute: "firstName",
								type: "required",
							},
							{
								attribute: "lastName",
								type: "required",
							},
							{
								attribute: "email",
								type: "required",
							},
							{
								attribute: "birthday",
								type: "optional",
							},
							{
								attribute: "phone",
								type: "optional",
							},
							{
								attribute: "startDate",
								type: "optional",
								de: "Startdatum",
							},
							{
								attribute: "endDate",
								type: "optional",
								de: "Befristet bis",
							},
							{
								attribute: "costCenter",
								type: "optional",
							},
							{
								attribute: "superiorPersonnelNumber",
								type: "optional",
								de: "Personalnummer des Reisekostenprüfers",
							},
							{
								attribute: "isTestUser",
								type: "optional",
								de: "Ist Test-Benutzer",
							},
						]}
					/>

					<CSVFileHandler
						onUpload={() => {
							setCsvImportType("employeeImport");
							setShowCSVModalUploadModal(true);
						}}
						uploadLabel="Benutzer CSV-Datei hochladen"
						downloadLabel="Benutzer Beispiel-Datei"
						csvData={employeeExampleCsv}
						csvFileName="benutzer-beispiel-datei"
						testId="download-csv-employee-example"
					/>
				</ImportCSVContainerBody>
			</ImportCSVContainer>

			<LineSpacer />
			<ImportCSVContainer>
				<Title>
					Import Budget Datei
					<CsvHelpModal type={"budgetImport"} />
				</Title>
				<ImportCSVContainerBody>
					<CsvHelpCard
						columns={[
							{
								attribute: "personnelNumber",
								type: "required",
							},
							{
								attribute: "startDate",
								type: "required",
								description: (
									<>
										Das Startdatum bezieht sich auf das Datum, an dem ein
										bestimmtes Budget eines Mitarbeiters für ihn verfügbar ist.
										<LineSpacer />
										Für Sachbezug muss das Startdatum ein Monat sein, der noch
										nicht begonnen hat. <br />
										Derzeit:{" "}
										{format(
											startOfMonth(addMonths(new Date(), 1)),
											"yyyy-MM-dd"
										)}
										.
									</>
								),
							},
							{
								attribute: "budget",
								type: "required",
							},
							{
								attribute: "budgetAmount",
								type: "required",
							},
							{
								attribute: "budgetCategories",
								type: "required",
							},
							{
								attribute: "endDate",
								type: "optional",
								description:
									"Bis zu diesem Datum kann das Budget eines Mitarbeiters verwendet werden. Danach ist es nicht mehr verfügbar.",
							},
							{
								attribute: "isOverflowBudget",
								type: "optional",
							},
							{
								attribute: "budgetAmountCorrection",
								type: "optional",
							},
						]}
					/>
					<CSVFileHandler
						onUpload={() => {
							setCsvImportType("budgetImport");
							setShowCSVModalUploadModal(true);
						}}
						uploadLabel="Budget CSV-Datei hochladen"
						downloadLabel="Budget Beispiel-Datei"
						csvData={budgetImportExampleCsv}
						csvFileName="budget-beispiel-datei"
						testId="download-csv-budget-example"
					/>
				</ImportCSVContainerBody>
			</ImportCSVContainer>

			<LineSpacer />

			<ImportCSVContainer>
				<Title>
					Import Dauerbeleg Datei
					<CsvHelpModal type={"contractImport"} />
				</Title>
				<ImportCSVContainerBody>
					<CsvHelpCardContainer>
						<CsvHelpCard
							header="Für Mobility-Kategorien außer jobVehicles und railcardEmployerFinanced"
							columns={[
								{
									attribute: "category",
									type: "required",
									description: (
										<>
											mobility.vehicleCosts.* <LineSpacer />
											mobility.longDistance.* <LineSpacer />
											mobility.sharing.*
										</>
									),
								},
								...commonContractReqCols,
								{
									attribute: "amountPerMonth",
									type: "required",
								},
								...commonContractOptCols,
								{
									attribute: "isFromThirdParty",
									type: "optional",
								},
							]}
						/>
						<CsvHelpCard
							header="Für jobVehicles"
							columns={[
								{
									attribute: "category",
									type: "required",
									description: <>mobility.jobVehicle.*</>,
								},
								...commonContractReqCols,
								{
									attribute: "vehicleType",
									type: "required",
								},
								{
									attribute: "leaseRate",
									type: "required",
								},
								...commonContractOptCols,
								{
									attribute: "leaseStartDate",
									type: "optional",
								},
								{
									attribute: "leaseEndDate",
									type: "optional",
								},
								{
									attribute: "insuranceRate",
									type: "optional",
								},
								{
									attribute: "serviceRate",
									type: "optional",
								},
								{
									attribute: "flatTaxRate",
									type: "optional",
								},
								{
									attribute: "expectedVendor",
									type: "optional",
								},
								{
									attribute: "expectedPurchasePrice",
									type: "optional",
								},
								{
									attribute: "serviceIntervalInMonths",
									type: "optional",
								},
								{
									attribute: "expectServiceVerification",
									type: "optional",
								},
								{
									attribute: "commuteDistanceInKm",
									type: "optional",
								},
								{
									attribute: "transferDate",
									type: "optional",
								},
								{
									attribute: "listPrice",
									type: "optional",
								},
							]}
						/>
						<CsvHelpCard
							header="Für railcardEmployerFinanced"
							columns={[
								{
									attribute: "category",
									type: "required",
									description: (
										<>mobility.longDistance.railcardEmployerFinanced</>
									),
								},
								...commonContractReqCols,
								{
									attribute: "amountPerMonth",
									type: "required",
								},
								{
									attribute: "settingsExpenses",
									type: "required",
								},
								{
									attribute: "settingsCommuting",
									type: "required",
								},
								{
									attribute: "settingsFringeBenefits",
									type: "required",
								},
								...commonContractOptCols,
							]}
						/>
						<CsvHelpCard
							header="Für alles andere"
							columns={[
								{
									attribute: "category",
									type: "required",
									description: (
										<>
											web <LineSpacer /> spend.*
										</>
									),
								},
								...commonContractReqCols,
								{
									attribute: "amountPerMonth",
									type: "required",
								},
								...commonContractOptCols,
							]}
						/>
						<Info>
							Alle Dauerbeleg-Kategorien sind{" "}
							<Link
								to={{
									pathname:
										"https://docs.lofino.de/csv-imports/contract/contract_categories/",
								}}
								target="_blank"
							>
								hier
							</Link>{" "}
							zu finden.
						</Info>
					</CsvHelpCardContainer>
					<CSVFileHandler
						onUpload={() => {
							setCsvImportType("contractImport");
							setShowCSVModalUploadModal(true);
						}}
						uploadLabel="Dauerbeleg CSV-Datei hochladen"
						downloadLabel="Dauerbeleg Beispiel-Datei"
						csvData={contractImportSampleCsv}
						csvFileName="dauerbeleg-beispiel-datei"
						testId="download-csv-contract-example"
					/>
				</ImportCSVContainerBody>
			</ImportCSVContainer>
			<LineSpacer />

			<ImportCSVContainer>
				<Title>Import Sachbezug Gutscheine</Title>
				<p>
					Dieser Abschnitt ist ausschließlich der Verwaltung von
					Sachbezug-Coupons über Cadooz gewidmet.
					<br />
					Details zum Bestellvorgang der Sachbezugs-Coupons finden Sie{" "}
					<a href="https://lofino.atlassian.net/wiki/x/FIBGHw">
						hier im Wissenshub
					</a>
					.
					<br />
					Hinweis: Wenn Sie die Sachbezug-Budgets für Benutzer verwalten
					möchten, verwenden Sie bitte den Abschnitt: <b>Import Budget Datei</b>
					.
				</p>
				<ImportCSVContainerBody>
					<CsvHelpCard
						columns={[
							{
								attribute: "Productktnr",
								type: "required",
							},
							{
								attribute: "Gutscheinwert",
								type: "required",
							},
							{
								attribute: "Gutscheincode",
								type: "required",
							},
							{
								attribute: "PIN",
								type: "required",
							},
							{
								attribute: "Ablaufdatum",
								type: "required",
							},
							{
								attribute: "eCard-Link",
								type: "required",
							},
							{
								attribute: "Iftd. Nr.",
								type: "optional",
							},
							{
								attribute: "Productktbezeichnung",
								type: "optional",
							},
							{
								attribute: "Url",
								type: "optional",
							},
						]}
					/>

					<ButtonWrapper>
						<Tooltip
							id={"download-sachbezug-user-list"}
							delay={{ show: 400, hide: 0 }}
							tooltip={
								downloadOrderFileIsDisable
									? "Der Download der CSV Datei ist erst nach dem 14 des Monats möglich."
									: ""
							}
						>
							<ButtonWithIcon
								variant="outline-primary"
								onClick={downloadSachbezugUser}
								disabled={downloadOrderFileIsDisable}
							>
								<IconWrapper>
									<Icon.Download />
								</IconWrapper>
								Bestell-Datei herunterladen
							</ButtonWithIcon>
						</Tooltip>
						<ButtonWithIcon
							onClick={() => {
								setCsvImportType("sachbezugImport");
								setShowCSVModalUploadModal(true);
							}}
						>
							<IconWrapper>
								<Icon.Upload />
							</IconWrapper>
							Gutschein CSV-Datei hochladen
						</ButtonWithIcon>
					</ButtonWrapper>
				</ImportCSVContainerBody>
			</ImportCSVContainer>
			{showCSVModalUploadModal && csvImportType && (
				<CommonModal handleClose={() => setShowCSVModalUploadModal(false)}>
					<UploadCSV
						onClickCloseModal={() => setShowCSVModalUploadModal(false)}
						csvImportType={csvImportType}
					/>
				</CommonModal>
			)}
			{downloadError && <ErrorAlert>{downloadError.displayText}</ErrorAlert>}
		</div>
	);
}

type Props = {
	onUpload: () => void;
	uploadLabel: string;
	downloadLabel: string;
	csvData: (Header & CSVDataAndHeader)[];
	csvFileName: string;
	testId: string;
};

const CSVFileHandler = (props: Props) => {
	return (
		<ButtonWrapper>
			<ButtonWithIcon onClick={props.onUpload}>
				<IconWrapper>
					<Icon.Upload />
				</IconWrapper>
				{props.uploadLabel}
			</ButtonWithIcon>
			<DownloadableCSV
				csvData={props.csvData}
				testId={props.testId}
				fileName={props.csvFileName}
			>
				<ButtonWithIcon variant="outline-primary">
					<IconWrapper>
						<Icon.Download />
					</IconWrapper>
					{props.downloadLabel}
				</ButtonWithIcon>
			</DownloadableCSV>
		</ButtonWrapper>
	);
};

const employeeExampleCsv: (Header & CSVDataAndHeader)[] = [
	{
		title: "personnelNumber",
		isSortable: true,
		accessor: "personalNo",
		data: ["p0001"],
	},
	{
		title: "salutation",
		isSortable: true,
		accessor: "title",
		data: ["Herr"],
	},
	{
		title: "firstName",
		isSortable: true,
		accessor: "firstname",
		data: ["John"],
	},
	{
		title: "lastName",
		isSortable: true,
		accessor: "lastname",
		data: ["Mayer"],
	},
	{
		title: "email",
		isSortable: true,
		accessor: "email",
		data: ["john.mayer@example.com"],
	},
	{
		title: "birthday",
		isSortable: true,
		accessor: "dateOfBirth",
		data: ["1980-01-02"],
	},
	{
		title: "phone",
		isSortable: true,
		accessor: "phone",
		data: ["+4900000000000"],
	},
	{
		title: "endDate",
		isSortable: true,
		accessor: "contractEnd",
		data: ["2030-01-02"],
	},
	{
		title: "costCenter",
		isSortable: true,
		accessor: "costCenter",
		data: ["123456abc"],
	},
	{
		title: "superiorPersonnelNumber",
		isSortable: true,
		accessor: "superiorPersonnelNumber",
		data: ["2990091"],
	},
	{
		title: "isTestUser",
		isSortable: true,
		accessor: "isTestUser",
		data: ["false"],
	},
];

const budgetImportExampleCsv: (Header & CSVDataAndHeader)[] = [
	{
		title: "personnelNumber",
		isSortable: true,
		accessor: "personalNo",
		data: ["p0001", "p0002", "p0002", "p0002", "p0002"],
	},
	{
		title: "startDate",
		isSortable: true,
		accessor: "startdate",
		data: [
			"2024-04-01",
			"2024-04-01",
			"2024-04-01",
			"2024-04-01",
			format(startOfMonth(addMonths(new Date(), 1)), "yyyy-MM-dd"),
		],
	},
	{
		title: "endDate",
		isSortable: true,
		accessor: "enddate",
		data: ["", "", "", "", ""],
	},
	{
		title: "budget",
		isSortable: true,
		accessor: "budget",
		data: ["mobility", "mobility", "web", "lunch", "sachbezug"],
	},
	{
		title: "budgetAmount",
		isSortable: true,
		accessor: "budgetamount",
		data: ["100,00", "50,00", "50,00", "108,45", "50,00"],
	},
	{
		title: "isOverflowBudget",
		isSortable: true,
		accessor: "overflowbudget",
		data: ["true", "false", "", "", ""],
	},
	{
		title: "budgetCategories",
		isSortable: true,
		accessor: "budgetCategories",
		data: [
			"mobility.sharing.bike,mobility.vehicleCosts.car.gasOrElectricity,mobility.commute.publicTransport.seasonTicket",
			"mobility.sharing.bike,mobility.vehicleCosts.car.gasOrElectricity,mobility.commute.publicTransport.seasonTicket",
			"",
			"",
			"",
		],
	},
];

const contractImportSampleCsv: (Header & CSVDataAndHeader)[] = [
	{
		title: "personnelNumber",
		isSortable: true,
		accessor: "personalNo",
		data: ["p0001", "p0002", "p0003"],
	},
	{
		title: "externalId",
		isSortable: true,
		accessor: "externalId",
		data: ["f001", "a001", "f002"],
	},
	{
		title: "startDate",
		isSortable: true,
		accessor: "startdate",
		data: ["01.04.2023", "01.04.2023", "01.04.2023"],
	},
	{
		title: "endDate",
		isSortable: true,
		accessor: "enddate",
		data: ["31.03.2025", "", "31.03.2025"],
	},
	{
		title: "leaseStartDate",
		isSortable: true,
		accessor: "leasestartdate",
		data: ["01.04.2023", "", "01.04.2023"],
	},
	{
		title: "leaseEndDate",
		isSortable: true,
		accessor: "leaseenddate",
		data: ["31.03.2025", "", ""],
	},
	{
		title: "category",
		isSortable: true,
		accessor: "category",
		data: [
			"mobility.jobVehicle.bike",
			"mobility.commute.publicTransport.annualTicket",
			"mobility.jobVehicle.car",
		],
	},
	{
		title: "name",
		isSortable: true,
		accessor: "name",
		data: [
			"Dienstfahrrad-Dauerbeleg vom 1. Juli 2023",
			"Deutschlandticket unbefristet",
			"Dienstauto-Dauerbeleg vom 1. Juli 2023",
		],
	},
	{
		title: "vehicleType",
		isSortable: true,
		accessor: "vehicletype",
		data: ["bike", "", "e-car"],
	},
	{
		title: "listPrice",
		isSortable: true,
		accessor: "listprice",
		data: ["1200", "", "12000"],
	},
	{
		title: "leaseRate",
		isSortable: true,
		accessor: "leaserate",
		data: ["139.75", "", "239,99"],
	},
	{
		title: "transferDate",
		isSortable: true,
		accessor: "transferdate",
		data: ["01.05.2023", "", "01.05.2023"],
	},
	{
		title: "amountPerMonth",
		isSortable: true,
		accessor: "amountpermonth",
		data: ["", "49", ""],
	},
	{
		title: "isFromThirdParty",
		isSortable: false,
		accessor: "isfromthirdparty",
		data: ["", "true", "false"],
	},
];

export const Info = styled.p`
	max-width: 80%;
`;

const Title = styled.p`
	font-weight: bold;
	font-size: 1.2rem;
`;

export const ButtonWithIcon = styled(Button)`
	display: block;
	width: 100%;
	a {
		color: inherit;
		text-decoration: inherit;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const ImportCSVContainer = styled.div`
	padding: 10px 25px;
`;

const ImportCSVContainerBody = styled.div`
	display: grid;
	grid-template-columns: calc(100% - clamp(20vw, 320px, 30vw)) clamp(
			20vw,
			320px,
			30vw
		);
	align-items: flex-start;
	padding: 0;
	gap: 30px;
`;

const CsvHelpCardContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;
