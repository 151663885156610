import React from "react";
import { Link } from "react-router-dom";
import Routes from "@service/navigation/routes";
import { Customer } from "@models";
import {
	usePaginatedFetchedData,
	useSortQueryParams,
	useServices,
} from "@hooks";
import {
	TableGeneric,
	Button,
	Pagination,
	PageContainer,
	PageHeadContainer,
	PageHeading,
	RelativeContainer,
	ButtonContainer,
	FlexRow,
	Icon,
	ScrollToTop,
	QueryStateIndicators,
	ScrollUpButton,
	MainBreadcrumbs,
} from "@components";
import CustomerRow from "./components/CustomerRow";
import { TableStylesPrimary } from "@components/TableContainer";
import { Header } from "@components/TableGeneric/TableTypes";

export default function CustomersPage() {
	const { customerService } = useServices();
	const sortQueryParams = useSortQueryParams();

	const headersTable: Header[] = [
		{
			title: "KdNr",
			isSortable: true,
			accessor: "customerNo",
			width: 100,
		},
		{ title: "Status", isSortable: false, width: 100 },
		{ title: "Name", isSortable: true, accessor: "name" },
		{
			title: "Erstellt",
			isSortable: true,
			accessor: "created",
		},
		{
			title: "Geändert",
			isSortable: true,
			accessor: "updated",
		},
		{
			title: "",
			accessor: "",
		},
	];

	const [customersQuery, refetch] = usePaginatedFetchedData(
		customerService.getCustomers
	);

	return (
		<>
			<ScrollToTop />
			<ScrollUpButton />
			<PageContainer>
				<FlexRow
					css={`
						width: 100%;
					`}
				>
					<MainBreadcrumbs />
					<ButtonContainer>
						<Link to={Routes.AllVouchers}>
							<Button variant="outline-secondary">
								<Icon.Vouchers /> Belege
							</Button>
						</Link>
					</ButtonContainer>
				</FlexRow>
				<PageHeadContainer>
					<PageHeading>Kundenübersicht</PageHeading>
					<ButtonContainer>
						<Link to={Routes.AddCustomer}>
							<Button variant="outline-secondary" size="sm">
								<Icon.Add /> Kunde hinzufügen
							</Button>
						</Link>
					</ButtonContainer>
				</PageHeadContainer>
				<RelativeContainer>
					{customersQuery.data && customersQuery.data.length > 0 && (
						<TableStylesPrimary>
							<TableGeneric
								headers={headersTable}
								data={customersQuery.data ?? []}
								sortOptions={sortQueryParams}
							>
								{(customer: Customer) => (
									<CustomerRow
										key={customer.customerNo}
										headers={headersTable}
										customer={customer}
										onUpdateCustomer={refetch}
									/>
								)}
							</TableGeneric>
						</TableStylesPrimary>
					)}

					<QueryStateIndicators queryState={customersQuery} />
					<Pagination queryState={customersQuery} />
				</RelativeContainer>
			</PageContainer>
		</>
	);
}
