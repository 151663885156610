import { ErrorBase } from "@util/errors";

export class ExcessBudgetDisabledError extends ErrorBase {
	static errorId = "excess-budget-disabled" as const;
	constructor() {
		super(
			"Der Monat kann nicht geschlossen werden, da es einen Fehler bei der Konfiguration des Überschussbudgets gibt. \n Bitte deaktiviere das Überschussbudget bei den betroffenen Usern oder aktivere das Überschussbudget in den Standorteinstellungen. "
		);
	}
}

export class UnreviewedVouchersError extends ErrorBase {
	constructor() {
		super(
			"Der Monat kann nicht geschlossen werden, da noch ungeprüfte Belege vorhanden sind. Sobald alle Belege akzeptiert oder abgelehnt worden sind, kannst du den Monat schließen"
		);
	}
}
