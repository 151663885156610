import { Properties } from "@util/types";
import * as yup from "yup";

export class UserRole {
	constructor(props: Properties<UserRole>) {
		this.resourceType = props.resourceType;
		this.resourceId = props.resourceId;
		this.role = props.role;
	}

	static fromJson(data: any): UserRole {
		if (!roleSchema.isValidSync(data)) {
			throw new Error("validation failed");
		}

		return new UserRole({
			resourceType: data.resourceType as ResourceType,
			resourceId: data.resourceId,
			role: data.role as Role,
		});
	}

	readonly resourceType: ResourceType;
	readonly resourceId: string;
	readonly role: Role;
}

type ResourceType = "customer" | "division";

const roles = [
	"super-admin",
	"customer-admin",
	"division-admin",
	"accounting-admin",
	"expenses-admin",
	"expenses-reviewer",
	"tax-control-assistant",
] as const;
type Role = typeof roles[number];

const roleSchema = yup.object().shape({
	resourceType: yup.string().required().oneOf(["customer", "division"]),
	resourceId: yup.string().required(),
	role: yup.string().required().oneOf(roles),
});

export const roleNameMapping: Record<Role, string> = {
	"super-admin": "Super-Admin",
	"customer-admin": "Kunden-Admin",
	"division-admin": "Standort-Admin",
	"accounting-admin": "Buchhaltungs-Admin",
	"expenses-admin": "Reisekosten-Admin",
	"expenses-reviewer": "Reisekosten-Prüfer",
	"tax-control-assistant": "TCA",
} as const;
