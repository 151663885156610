import React, { useCallback } from "react";
import { User } from "@models";
import { useFetchedData, useRoles, useServices } from "@hooks";
import { QueryStateIndicators } from "@components";
import DivisionsList from "./DivisionsList";
import { envConfig } from "@service/config";

type Props = {
	user: User;
};

export default function UserRolesPage(props: Props) {
	const { customerId, divisionId } = props.user;
	const rolesQuery = useRoles(props.user);
	const divisionsQuery = useDivisions(customerId);

	return (
		<>
			<p>
				Berechtigungen dieses Mitarbeiters auf{" "}
				<a
					href={`${envConfig.portalBaseUrl}/customers/${customerId}/divisions/${divisionId}/settings?tab=roles#as_internal`}
				>
					{envConfig.portalBaseUrl}
				</a>{" "}
				für die jeweiligen Standorte des Kunden.
				<br />
				<strong>
					Berechtigungen werden im Arbeitgeberportal konfiguriert.
				</strong>
			</p>

			<QueryStateIndicators queryState={[rolesQuery, divisionsQuery]} />
			{rolesQuery.state === "success" && divisionsQuery.state === "success" && (
				<DivisionsList
					user={props.user}
					divisions={divisionsQuery.data}
					roles={rolesQuery.data}
				/>
			)}
		</>
	);
}

function useDivisions(customerId: string) {
	const { divisionService } = useServices();

	const fetchData = useCallback(async () => {
		return divisionService.getDivisions(customerId);
	}, [customerId, divisionService]);

	const [query] = useFetchedData(fetchData);
	return query;
}
