import { Voucher } from "@models";
import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "@theme/theming";
import { formatDate } from "@service/util";
import Accordion from "react-bootstrap/Accordion";
import { FaChevronDown as ExpandIcon } from "react-icons/fa";
import LoadingIndicator from "@components/LoadingIndicator";
import ErrorAlert from "@components/ErrorAlert";
import Routes from "@service/navigation/routes";
import { useEntityHistory } from "@hooks";
import { DomainEvent } from "@models/DomainEvent";

type Props = {
	voucher: Voucher;
};

function VoucherHistory(props: Props) {
	const voucherHistories = useEntityHistory(props.voucher.voucherId, "voucher");
	const [expandedHistoryIndex, setExpandedHistoryIndex] = useState<
		number | null
	>(null);

	const handleExpandHistory = (index: number) => {
		setExpandedHistoryIndex((preVal) => (preVal === index ? null : index));
	};

	const mapEventTypeToText = (event: string) => {
		switch (event) {
			case VoucherDomainEvent.VoucherDraftCreated:
				return "Belegentwurf erstellt";
			case VoucherDomainEvent.VoucherSubmitted:
			case VoucherDomainEvent.ExpensesVoucherSubmitted:
				return "Beleg eingereicht";
			case VoucherDomainEvent.VoucherUpdatedByReviewer:
				return "Beleg aktualisiert";
			case VoucherDomainEvent.VoucherApproved:
				return "Beleg genehmigt";
			case VoucherDomainEvent.VoucherRejected:
				return "Beleg abgelehnt";
			case VoucherDomainEvent.VoucherUpgradedToContract:
				return "In Dauerbeleg umgewandelt";
			case VoucherDomainEvent.VoucherMovedEvent:
				return "Beleg verschoben";
			case VoucherDomainEvent.ContractVoucherCopiedEvent:
				return "Beleg kopiert";
		}
	};

	const isKnownDomainEvent = (domainEvent: DomainEvent) => {
		return Object.values(VoucherDomainEvent).includes(
			domainEvent.type as VoucherDomainEvent
		);
	};

	return (
		<Container>
			{(voucherHistories.state === "fetching" ||
				voucherHistories.state === "initialFetching") && <LoadingIndicator />}

			{voucherHistories.state === "error" && <ErrorAlert />}

			{voucherHistories.state === "success" &&
				voucherHistories.data
					.filter(isKnownDomainEvent)
					.map((history, index) => {
						const { data, principal, occurredAt, type } = history;
						let changedFrom = null;
						let changedTo = null;
						if ("data" in data) {
							changedTo = data.data;
							if ("changedFrom" in data) {
								changedFrom = data.changedFrom;
							}
						} else {
							changedTo = data;
						}

						const userUrl =
							principal.customerId && principal.divisionId
								? Routes.UserBasicData.makePath({
										customerId: principal.customerId,
										divisionId: principal.divisionId,
										userId: principal.userId,
								  })
								: undefined;

						return (
							<History key={index}>
								<HistoryHead>
									<div>
										<span>
											<span>{mapEventTypeToText(type)}</span>
											<LightText> am </LightText>
											<span>{formatDate(occurredAt, "dd. MMMM yyyy")}</span>
											<LightText> um </LightText>
											<span>{formatDate(occurredAt, "HH:mm:ss")}</span>
										</span>
										<br />
										<LightText>von </LightText>
										<CausedByName href={userUrl}>
											{principal.fullName ?? principal.userId}
										</CausedByName>
									</div>
									<Icon
										eventKey={index.toString()}
										onClick={() => handleExpandHistory(index)}
										expanded={expandedHistoryIndex === index}
									>
										<ExpandIcon />
									</Icon>
								</HistoryHead>
								<Accordion.Collapse eventKey={index.toString()}>
									<ChangesContainer
										style={{
											justifyContent:
												changedTo && changedFrom
													? "space-between"
													: "flex-start",
										}}
									>
										{!!changedFrom && (
											<div>
												{changedFrom && <span>Von:</span>}
												<Changes>
													{JSON.stringify(changedFrom, null, 2)}
												</Changes>
											</div>
										)}
										{!!changedTo && (
											<div>
												{!!changedFrom && <span>Zu:</span>}
												<Changes>{JSON.stringify(changedTo, null, 2)}</Changes>
											</div>
										)}
									</ChangesContainer>
								</Accordion.Collapse>
							</History>
						);
					})}
		</Container>
	);
}

enum VoucherDomainEvent {
	VoucherDraftCreated = "VoucherDraftCreated",
	VoucherSubmitted = "VoucherSubmitted",
	VoucherUpdatedByReviewer = "VoucherUpdatedByReviewer",
	VoucherApproved = "VoucherApproved",
	VoucherRejected = "VoucherRejected",
	VoucherUpgradedToContract = "VoucherUpgradedToContract",
	VoucherMovedEvent = "VoucherMovedEvent",
	ExpensesVoucherSubmitted = "ExpensesVoucherSubmitted",
	ContractVoucherCopiedEvent = "ContractVoucherCopiedEvent",
}

const Container = styled(Accordion)`
	position: relative;
	min-height: 100px;
`;

const History = styled.div`
	font-size: 0.8rem;
	padding: 0.7rem 0.3rem;
	border-bottom: 0.5px solid ${colors.gray.g200} !important;
	&:first-child {
		padding-top: 0;
	}
`;

const LightText = styled.span`
	color: ${colors.gray.g400};
`;

const CausedByName = styled.a`
	font-weight: 500;

	&:not([href]) {
		pointer-events: none;
		color: ${colors.text};
	}
`;

const HistoryHead = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

type IconProps = {
	expanded: boolean;
};

const Icon = styled(Accordion.Toggle)<IconProps>`
	rotate: ${(props) => (props.expanded ? 180 : 0)}deg;
	border: none;
	background-color: transparent;
	color: ${colors.gray.g400};
	transition: rotate 0.3s;
	&:hover {
		color: ${colors.primary.p200};
	}
`;

const ChangesContainer = styled.div`
	background: ${colors.gray.g100};
	display: flex;
	padding: 0.5rem;
	border-radius: 0.3rem;
	margin-top: 0.3rem;
`;
const Changes = styled.pre`
	margin: 0;
	white-space: pre-wrap;
`;

export default VoucherHistory;
