import { ErrorAlert, ErrorImage, PageContainer } from "@components";
import { MaintenanceServerError, UnauthorizedError } from "@service/errors";
import React, { useMemo } from "react";

export default function MaintenancePage(props: { error: unknown }) {
	const { error } = props;

	const message = useMemo(() => {
		if (error instanceof MaintenanceServerError) {
			return error.displayText;
		}

		if (error instanceof UnauthorizedError) {
			return "Du hast nicht die notwendigen Berechtigungen um diese App zu benutzen.";
		}

		return "Beim Laden ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.";
	}, [error]);

	return (
		<PageContainer>
			<ErrorAlert>{message}</ErrorAlert>
			<ErrorImage />
		</PageContainer>
	);
}
