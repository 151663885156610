import { Properties } from "@util/types";
import * as yup from "yup";

export const userTypes = [
	"backofficeUser",
	"appUser",
	"publicAPI",
	"job",
	"migration",
	"portalUser",
	"portalUserInternal",
	"legacyPortalUser",
] as const;
export type UserType = typeof userTypes[number];

type DomainEventPrincipalWithDetails = {
	userId: string;
	userType: UserType;
	fullName?: string;
	customerId?: string;
	divisionId?: string;
};

export class DomainEvent {
	constructor(props: Properties<DomainEvent>) {
		this.occurredAt = props.occurredAt;
		this.principal = props.principal;
		this.type = props.type;
		this.data = props.data;
	}

	readonly occurredAt: Date;
	readonly principal: DomainEventPrincipalWithDetails;
	readonly type: string;
	readonly data: object;

	static fromJson(data: any): DomainEvent {
		const domainEvent = domainEventSchema.validateSync(data);
		return new DomainEvent({
			occurredAt: new Date(domainEvent.occurredAt),
			principal: domainEvent.principal,
			type: domainEvent.type,
			data: domainEvent.data,
		});
	}
}

const principalSchema = yup
	.object()
	.shape({
		userId: yup.string().required(),
		userType: yup.string().oneOf(userTypes).required(),
		fullName: yup.string().optional(),
		customerId: yup.string().uuid().optional(),
		divisionId: yup.string().uuid().optional(),
	})
	.required();

const domainEventSchema = yup.object({
	occurredAt: yup.date().required().typeError("invalid date"),
	principal: principalSchema,
	type: yup.string().required(),
	data: yup.mixed().required(),
});
