import React from "react";
import { useForm } from "react-hook-form";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import {
	ActionButton,
	Button,
	CheckboxToggle,
	Form,
	Input,
	InputValidation,
	Label,
	RequiredMarker,
	ScrollToTop,
	Select,
} from "@components";
import Routes, { CustomerRouteParams } from "@service/navigation/routes";
import { QueryState, useStaticData } from "@hooks";
import Customer, { CustomerStatus, NewCustomer } from "@models/Customer";
import { REQUIRED_ERROR_MESSAGE } from "@util/errors";

type Props = {
	customer?: NewCustomer;
	handleOnSubmit: (customer: NewCustomer) => void;
	cmdState: QueryState<Customer>;
};

export default function CustomerForm(props: Props) {
	const { customer, handleOnSubmit, cmdState } = props;
	const {
		params: { customerId },
	} = useRouteMatch<CustomerRouteParams>();

	const { legalForms } = useStaticData();

	const { formState, register, handleSubmit, setValue, watch } =
		useForm<NewCustomer>({
			defaultValues: customer ?? {
				name: "",
				status: CustomerStatus.Trial,
				legalForm: legalForms[0].description,
			},
			mode: "onChange",
		});

	const isTrialCustomer = watch("status") === CustomerStatus.Trial;
	const toggleStatus = () => {
		setValue(
			"status",
			isTrialCustomer ? CustomerStatus.Active : CustomerStatus.Trial
		);
	};

	const isEditMode = customerId !== undefined;

	return (
		<>
			<ScrollToTop />
			<FormContainer>
				{isEditMode ? (
					<Heading>Kunden bearbeiten</Heading>
				) : (
					<Heading>Neuer Kunde</Heading>
				)}
				<Form onSubmit={handleSubmit((data) => handleOnSubmit(data))}>
					<InputValidation error={formState.errors.name}>
						<InputContainer className="form-group">
							<Label htmlFor="name">
								Name <RequiredMarker />
							</Label>
							<Input
								{...register("name", { required: REQUIRED_ERROR_MESSAGE })}
								type="text"
								id="name"
							/>
						</InputContainer>
					</InputValidation>
					<InputContainer className="form-group">
						<Label htmlFor="legalForm">
							Rechtsform <RequiredMarker />
						</Label>
						<Select
							{...register("legalForm", { required: true })}
							id="legalForm"
						>
							{legalForms.map((l) => (
								<option key={l.legalFormId} value={l.description}>
									{l.description}
								</option>
							))}
						</Select>
					</InputContainer>
					{!isEditMode && (
						<InputContainer className="form-group">
							<CheckboxToggle
								label="Ist Trialkunde"
								data-testid="customer-form-isTrialCustomer"
								onChange={toggleStatus}
								disabled={isEditMode}
								checked={isTrialCustomer}
							/>
						</InputContainer>
					)}
					<InputContainer>
						<ActionButton
							type="submit"
							query={cmdState}
							successContent={"Gespeichert"}
						>
							Speichern
						</ActionButton>
						<Link to={Routes.Customers.makePath()}>
							<Button type="button" data-testid="loginButton">
								Zurück
							</Button>
						</Link>
					</InputContainer>
				</Form>
			</FormContainer>
		</>
	);
}

const InputContainer = styled.div``;

const Heading = styled.h3`
	text-align: center;
`;

const FormContainer = styled.div`
	margin: 0 auto;
	width: 350px;
`;
