import DialogProvider from "@components/DialogProvider";
import { QueryState } from "@hooks";
import { keycloak } from "@service/auth/keycloak";
import { Optional } from "@util/types";
import "bootstrap/dist/css/bootstrap.min.css";
import LoginPageOidc from "pages/auth/LoginPage/LoginPageOidc";
import MaintenancePage from "pages/maintenance/maintenancePage";
import React, { useEffect, useState } from "react";
import {
	Redirect,
	Route,
	BrowserRouter as Router,
	Switch,
} from "react-router-dom";
import "./App.css";
import { EnvironmentBanner, Header } from "./components";
import LoadingIndicator from "./components/LoadingIndicator";
import {
	GlobalState,
	GlobalStateContext,
	defaultGlobalState,
} from "./context/GlobalContext";
import { ServiceContext, defaultServices } from "./context/ServiceContext";
import StaticDataContext, { StaticData } from "./context/StaticDataContext";
import { initGlobalState } from "./context/initGlobalState";
import { useInitialStaticData } from "./hooks/useInitialStaticData";
import CustomersPage from "./pages/customers/CustomersPage";
import AddCustomerPage from "./pages/customers/components/AddCustomerPage";
import EditCustomerPage from "./pages/customers/components/EditCustomerPage";
import DivisionsPage from "./pages/division/DivisionsPage";
import { AddDivisionPage } from "./pages/division/components/AddDivisionPage";
import UserDetailsPage from "./pages/user/UserDetailsPage";
import VouchersPage from "./pages/vouchers/VouchersPage";
import Routes from "./service/navigation/routes";
import { GlobalStyle } from "./theme/GlobalStyle";

function App() {
	const [appState, setAppState] = useState<GlobalState | undefined>(undefined);
	const updateState = (newState: Partial<GlobalState>) =>
		setAppState({ ...appState, ...newState } as GlobalState);

	useEffect(() => {
		void initGlobalState().then(setAppState);
	}, []);

	useLogoutCallback(() =>
		setAppState((current) => ({
			...current,
			auth: defaultGlobalState.auth,
		}))
	);

	const staticData = useInitialStaticData(
		defaultServices,
		appState?.auth.isLoggedIn ?? false
	);

	if (!appState) return <LoadingIndicator />;

	// Catch initial data loading error
	if (staticData.state === "error") {
		return <MaintenancePage error={staticData.error} />;
	}

	return (
		<>
			<GlobalStyle />
			<GlobalStateContext.Provider
				value={{ globalState: appState, updateGlobalState: updateState }}
			>
				<ServiceContext.Provider value={defaultServices}>
					<DialogProvider>
						<EnvironmentBanner />
						<Router>
							<AppRoutes appState={appState} staticData={staticData} />
						</Router>
					</DialogProvider>
				</ServiceContext.Provider>
			</GlobalStateContext.Provider>
		</>
	);
}

export default App;

type AppRouteProps = {
	appState: GlobalState;
	staticData: QueryState<Optional<StaticData>>;
};

function AppRoutes(props: AppRouteProps) {
	const { appState, staticData } = props;

	if (!appState.auth.isLoggedIn) {
		return <LoginPageOidc />;
	}

	if (!staticData.data?.hasValue()) {
		return null;
	}

	return (
		<StaticDataContext.Provider value={staticData.data.value}>
			<Header />
			<Switch>
				<Route path={Routes.EditCustomer.route} component={EditCustomerPage} />
				<Route path={Routes.AddCustomer} component={AddCustomerPage} />
				<Route
					/**
					 * Since this route also matches the UserDetailsPage we have to make sure it's declared before UserDetails.
					 * Otherwise the app assumes we want to view the details of a user with id "new".
					 */
					path={Routes.AddUser.route}
					exact
					component={DivisionsPage}
				/>
				<Route path={Routes.CsvImports.route} exact component={DivisionsPage} />
				<Route path={Routes.UserDetails.route} component={UserDetailsPage} />
				<Route exact path={Routes.Customers.route} component={CustomersPage} />
				<Route
					path={Routes.AddDivision.route}
					exact
					component={AddDivisionPage}
				/>
				<Route path={Routes.Division.route} component={DivisionsPage} />
				<Route path={Routes.AllVouchers} component={VouchersPage} />
				<Redirect to={Routes.Customers.makePath()} />
			</Switch>
		</StaticDataContext.Provider>
	);
}

function useLogoutCallback(logoutCallback: () => void) {
	useEffect(() => {
		keycloak()
			.then((kc) => {
				kc.onAuthLogout = logoutCallback;
			})
			.catch(console.error);
	}, []);
}
