export const envConfig = {
	apiBaseUrl: requireEnv("REACT_APP_API_BASE_URL"),
	apiUrlPrefix: requireEnv("REACT_APP_API_URL_PREFIX"),
	auth: {
		url: requireEnv("REACT_APP_KEYCLOAK_URL"),
		realm: requireEnv("REACT_APP_KEYCLOAK_REALM"),
		clientId: requireEnv("REACT_APP_KEYCLOAK_CLIENT"),
		lofinoIdp: requireEnv("REACT_APP_KEYCLOAK_LOFINO_IDP"),
	},
	featureToggle: {
		tcaGuidelines:
			requireEnv("REACT_APP_TOGGLE_TCA_GUIDELINES", "false") === "true",
	},
	portalBaseUrl: requireEnv("REACT_APP_PORTAL_BASE_URL"),
};

function requireEnv(name: string, defaultValue?: string): string {
	const value = process.env[name] ?? defaultValue;
	if (process.env.NODE_ENV === "test") {
		return "MOCK_" + name;
	}
	if (!value) {
		throw new Error(`Environment variable ${name} is not set`);
	}
	return value;
}
