import { ErrorBase } from "@util/errors";
import { MonthString } from "@util/types";

export class MonthClosedForChangesError extends ErrorBase {
	static fromErrorResponse(payload: any): MonthClosedForChangesError {
		return new MonthClosedForChangesError(payload.month);
	}

	static errorId = "accounting-month-closed";

	override get displayText(): string {
		return `Änderung konnte nicht durchgeführt werden: Der Monat ${this.month} ist bereits abgeschlossen.`;
	}

	constructor(readonly month: MonthString) {
		super();
	}
}

export class ThirdPartyVouchersNotEnabledError extends ErrorBase {
	static fromErrorResponse(): ThirdPartyVouchersNotEnabledError {
		return new ThirdPartyVouchersNotEnabledError();
	}

	static errorId = "third-party-vouchers-not-enabled";

	constructor() {
		super("Belege für Drittpersonen sind nicht erlaubt in diesem Standort");
	}
}
export class ThirdPartyCategoryNotAllowedError extends ErrorBase {
	static fromErrorResponse(): ThirdPartyCategoryNotAllowedError {
		return new ThirdPartyCategoryNotAllowedError();
	}

	static errorId = "third-party-category-not-allowed";

	constructor() {
		super(
			"Die Kategorie dieses Belegs wird nicht für Belege von Drittpersonen unterstützt"
		);
	}
}
