import { Card } from "@components";
import { Division } from "@models";
import { roleNameMapping, UserRole } from "@models/UserRole";
import React from "react";

type Props = {
	division: Division;
	roles: UserRole[];
};

export default function UserDivisionRoles(props: Props) {
	const { division, roles } = props;

	return (
		<Card
			css={`
				margin-bottom: 16px;
			`}
		>
			<h4>{division.name}</h4>
			<hr />
			{roles.length === 0 ? (
				<p>Keine Rollen</p>
			) : (
				<ul>
					{roles.map((userRole) => (
						<li>{roleNameMapping[userRole.role]}</li>
					))}
				</ul>
			)}
		</Card>
	);
}
