import { Division, User } from "@models";
import { UserRole } from "@models/UserRole";
import _ from "lodash";
import React from "react";
import UserDivisionRoles from "./UserDivisionRoles";
import UserCustomerRoles from "./UserCustomerRoles";

type Props = {
	user: User;
	roles: UserRole[];
	divisions: Division[];
};

export default function DivisionsList(props: Props) {
	const { divisions, user, roles } = props;

	const rolesLookup = _.groupBy(roles, (role) => role.resourceId);

	const [thisDivisions, otherDivisions] = _.partition(
		divisions,
		(d) => d.divisionId === user.divisionId
	);

	const [thisDivision] = thisDivisions;
	const sortedOtherDivisions = _.sortBy(otherDivisions, (d) => d.name);
	const hasOtherDivisions = otherDivisions.length > 0;

	return (
		<>
			<UserCustomerRoles
				customerId={thisDivision.customerId}
				roles={rolesLookup[thisDivision.customerId] ?? []}
			/>

			{hasOtherDivisions && <h3>Eigener Standort</h3>}
			<UserDivisionRoles
				division={thisDivision}
				roles={rolesLookup[thisDivision.divisionId] ?? []}
			/>

			{hasOtherDivisions && (
				<div>
					<h3>Andere Standorte des Kunden</h3>
				</div>
			)}
			{sortedOtherDivisions.map((div) => (
				<UserDivisionRoles
					key={div.divisionId}
					division={div}
					roles={rolesLookup[div.divisionId] ?? []}
				/>
			))}
		</>
	);
}
