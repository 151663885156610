export class ErrorBase extends Error {
	/** this text is shown in the UI if the error is handled by the right components */
	get displayText(): string {
		return this.message || "Es ist ein Fehler aufgetreten.";
	}
}

/** an Error must conform to this interface to be handled automatically and must be added to the `knownErrors` list */
export type DeserializableFromResponse<T extends ErrorBase> = {
	/** this id is matched with the `type` field in the response body */
	errorId: string;
} & (
	| {
			fromErrorResponse(payload: Record<string, unknown>): T;
	  }
	| {
			new (): T;
	  }
);

export const REQUIRED_ERROR_MESSAGE = "Dieses Feld wird benötigt";

export function getErrorFromResponse(
	e: any
): { type: string; [k: string]: unknown } | { type: undefined } {
	const type = e.response?.data?.type;

	if (type && typeof type === "string") {
		return { type, ...e.response.data };
	}
	return { type: undefined };
}
