import {
	CheckboxToggle,
	FormGroup,
	Input,
	InputContainer,
	Label,
	NoAutoFillInput,
	RequiredMarker,
	TextArea,
} from "@components";
import { UpdatedDivision } from "@models/Division";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import JobRadSettings from "../JobRadSettings";
import styled from "styled-components";
import { Container, Row } from "../StyledComponents";

export default function BasicDataSettings() {
	const { control, register, getValues, unregister } =
		useFormContext<UpdatedDivision>();
	const jobradSettings = getValues("providerSettings.jobrad");
	const [isJobRad, setIsJobRad] = useState(!!jobradSettings);

	const toggleJobRadSetting = () => {
		setIsJobRad((preVal) => {
			if (preVal) {
				unregister("providerSettings.jobrad");
			}
			return !preVal;
		});
	};

	return (
		<>
			<InputContainer>
				<Label htmlFor="name">
					Name <RequiredMarker />
				</Label>
				<Input
					id="name"
					as={NoAutoFillInput}
					control={control}
					{...register("name", { required: true })}
					data-testid="division-form-name"
				/>
			</InputContainer>
			<Container>
				<h4>Kontaktdaten</h4>
				<ContactData>
					<InputContainer>
						<Label htmlFor="phone">Telefon</Label>
						<Input
							id="phone"
							as={NoAutoFillInput}
							control={control}
							{...register("phone")}
							data-testid="division-form-phone"
						/>
					</InputContainer>
					<InputContainer>
						<Label htmlFor="fax">Telefax</Label>
						<Input
							id="fax"
							as={NoAutoFillInput}
							control={control}
							{...register("fax")}
							data-testid="division-form-fax"
						/>
					</InputContainer>
					<InputContainer>
						<Label htmlFor="stid">Steuernummer</Label>
						<Input
							id="stid"
							as={NoAutoFillInput}
							control={control}
							{...register("stid")}
							data-testid="division-form-stid"
						/>
					</InputContainer>
					<InputContainer>
						<Label htmlFor="street">Straße</Label>
						<Input
							id="street"
							as={NoAutoFillInput}
							control={control}
							{...register("street")}
							data-testid="division-form-street"
						/>
					</InputContainer>
					<InputContainer>
						<Label htmlFor="zip">PLZ</Label>
						<Input
							id="zip"
							as={NoAutoFillInput}
							control={control}
							{...register("zip")}
							data-testid="division-form-zip"
						/>
					</InputContainer>
					<InputContainer>
						<Label htmlFor="city">Ort</Label>
						<Input
							id="city"
							as={NoAutoFillInput}
							control={control}
							{...register("city")}
							data-testid="division-form-city"
						/>
					</InputContainer>
					<InputContainer>
						<Label htmlFor="country">Land</Label>
						<Input
							id="country"
							as={NoAutoFillInput}
							control={control}
							{...register("country")}
							data-testid="division-form-country"
						/>
					</InputContainer>
					<InputContainer>
						<Label htmlFor="ustid">Umsatzsteuer-Identifikationsnummer</Label>
						<Input
							id="ustid"
							as={NoAutoFillInput}
							control={control}
							{...register("ustid")}
							data-testid="test-ustid"
						/>
					</InputContainer>
				</ContactData>
				<Row>
					<CheckboxToggle
						label="Ist Hauptsitz"
						data-testid="division-form-isMainDivision"
						{...register("isMainDivision")}
					/>
					<CheckboxToggle
						label="Nutzt JobRad"
						checked={isJobRad}
						onChange={toggleJobRadSetting}
					/>
				</Row>
				{isJobRad && <JobRadSettings />}
				<FormGroup>
					<Label htmlFor="comment">Kommentar</Label>
					<TextArea
						id="comment"
						as={NoAutoFillInput}
						control={control}
						{...register("comment")}
						data-testid="division-form-comment"
					/>
				</FormGroup>
			</Container>
		</>
	);
}

const ContactData = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1rem;
	> div {
		min-width: 300px;
		max-width: calc(50% - 0.5rem);
	}
	@media (max-width: 750px) {
		flex-direction: column;
		> div {
			min-width: 100%;
		}
	}
`;
